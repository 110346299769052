<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCard class="p-4">
            <CCardBody>
              <ValidationObserver v-slot="{ invalid }">
                <CForm @submit.prevent="onLogin">
                  <h1 class="mb-3">Anmeldung</h1>

                  <ValidationProvider rules="required|email" immediate v-slot="{ errors }">
                    <CInput
                      placeholder="E-Mail-Adresse"
                      autocomplete="username email"
                      :isValid="errors.length ==  0"
                      v-model="username"
                    >
                      <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                      <template #invalid-feedback>
                        <em class="error invalid-feedback">Keine gültige E-Mail-Adresse.</em>
                      </template>
                    </CInput>
                  </ValidationProvider>

                  <ValidationProvider rules="required|min:6" immediate v-slot="{ errors }">
                    <CInput
                      placeholder="Passwort"
                      type="password"
                      autocomplete="curent-password"
                      :isValid="errors.length ==  0"
                      v-model="password"
                    >
                      <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                      <template #invalid-feedback>
                        <em class="error invalid-feedback">Das Passwort muss eine Länge von 6 Zeichen besitzen.</em>
                      </template>
                    </CInput>
                  </ValidationProvider>
                  <CRow>
                    <CCol col="6">
                      <CButton color="secondary" class="px-4" v-on:click="passwordForgotten()">Passwort vergessen?</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="success" class="px-4" :disabled="invalid" type="submit">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </CCardBody>
          </CCard>
          <cAlert color="danger" class="mt-3" v-if="hasError">
            <strong>Authentifizierung nicht erfolgreich!</strong>
            <br>
            <em>Antwort des Servers:</em>  {{error}}
          </cAlert>
        </CCol>
      </CRow>

    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'app-login',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    hasError () {
      return this.$store.getters['security/hasError']
    },
    error () {
      return this.$store.getters['security/error']
    }
  },
  methods: {
    onLogin () {
      const { username, password } = this
      this.$store.dispatch('security/AUTH_LOGIN', { username, password }).then(() => {
        this.$router.push({ path: '/protokoll' })
      })
    },
    passwordForgotten (projektid) {
      this.$router.push({ path: '/security/password' })
    }
  }
}
</script>
